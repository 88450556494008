import service from './request'

export function getAllOptions() {
  return service.get(`/options`)
}

export function getOptions(type, param = null) {
  return service.get(`/options/${type}`, param)
}

export function getImageList(param) {
  return service.get(`/images`, param)
}

// 获取所有配置
export function getAllSetting() {
  return service.get(`/settings/all`)
}

// 获取汇付配置
export function getAdapaySetting() {
  return service.get(`/settings/adapay`)
}

// 更新配置
export function updateSetting(type, param) {
  return service.post(`/settings/${type}`, param)
}

export function getCustomerServiceSetting() {
  return service.get(`/settings/platformCustomerServiceAccount`)
}

export function updateCustomerServiceSetting(param) {
  return service.post(`/settings/platformCustomerServiceAccount`, param)
}

// APP更新
export function updateAppVersion(id, param) {
  return service.post(`/appVersions/${id}`, param)
}

// APP热更新
export function updateAppWgtVersion(id, param) {
  return service.post(`/appVersions/${id}`, param)
}

export function getSystemNotices(param) {
  return service.get(`/chatBusiness/notices`, param)
}

export function sendSystemNotice(param) {
  return service.post(`/chatBusiness/sendNotice`, param)
}

export function getSystemMessages(param) {
  return service.get(`/chatBusiness/messages`, param)
}

export function sendSystemMessage(param) {
  return service.post(`/chatBusiness/sendMessage`, param)
}

export function getSitePromptData() {
  return service.get(`/promptData`)
}

export function getAppHomeToyGoodsListIllustratedSettingList() {
  return service.get(`/appHomeToyGoodsListIllustratedSetting`)
}

export function getAppHomeToyGoodsListIllustratedSettingDetail(id) {
  return service.get(`/appHomeToyGoodsListIllustratedSetting/${id}`)
}

export function saveAppHomeToyGoodsListIllustratedSetting(data) {
  return service.post(`/appHomeToyGoodsListIllustratedSetting`, data)
}

export function updateAppHomeToyGoodsListIllustratedSettingData(id, data) {
  return service.patch(`/appHomeToyGoodsListIllustratedSetting/${id}`, data)
}

export function delAppHomeToyGoodsListIllustratedSetting(id) {
  return service.delete(`/appHomeToyGoodsListIllustratedSetting/${id}`)
}

export function getSiteStatistics(param) {
  return service.get(`/siteStatistics`, param)
}